import { useState, useEffect, useLayoutEffect } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

const useElementSize = target => {
  const [size, setSize] = useState();
  const canUseDOM = typeof window !== 'undefined';
  const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect;

  useIsomorphicLayoutEffect(() => {
    if (target && target.getBoundingClientRect) {
      setSize(target.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, entry => setSize(entry?.contentRect));

  return size;
};

export default useElementSize;
