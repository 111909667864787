import { useCallback, useEffect, useState } from 'react';

const SEARCH_HISTORY_STORAGE_KEY = 'OB_SEARCH_HISTORY';
const SEARCH_HISTORY_MAX_LENGTH = 10;
const SEARCH_HISTORY_UPDATED_EVENT_NAME = 'SEARCH_HISTORY_UPDATED';

// Duplicate behavior can be
// ignored - skips adding item to history
// update - pushes item to top, removes duplicated old one
// duplicate - pushes item without checks
const SEARCH_DEFAULT_DUPLICATE_BEHAVIOR = 'update';

export const emitSearchHistoryUpdatedEvent = () => {
  window.dispatchEvent(new CustomEvent(SEARCH_HISTORY_UPDATED_EVENT_NAME));
};

export const getSearchHistory = () => {
  if (typeof window === 'undefined') {
    return [];
  }
  const searchHistory = localStorage.getItem(SEARCH_HISTORY_STORAGE_KEY);
  return searchHistory ? JSON.parse(searchHistory) : [];
};

export const saveSearchHistory = searchHistory => {
  localStorage.setItem(SEARCH_HISTORY_STORAGE_KEY, JSON.stringify(searchHistory));
  emitSearchHistoryUpdatedEvent();
};

export const clearSearchHistory = () => {
  localStorage.removeItem(SEARCH_HISTORY_STORAGE_KEY);
  emitSearchHistoryUpdatedEvent();
};

export const pushSearchHistoryItem = (query, type, duplicateBehavior = SEARCH_DEFAULT_DUPLICATE_BEHAVIOR) => {
  const searchHistory = getSearchHistory();
  if (duplicateBehavior !== 'duplicate') {
    const duplicateIndex = searchHistory.findIndex(item => item?.query === query && item.type === type);
    if (duplicateIndex !== -1) {
      if (duplicateBehavior === 'ignored') {
        return;
      }
      searchHistory.splice(duplicateIndex, 1);
    }
  }
  searchHistory.unshift({ query, type });
  saveSearchHistory(searchHistory.slice(0, SEARCH_HISTORY_MAX_LENGTH));
};

export const removeSearchHistoryItem = index => {
  const searchHistory = getSearchHistory();
  searchHistory.splice(index, 1);
  saveSearchHistory(searchHistory);
};

export const useSearchHistory = () => {
  const [searchHistory, setSearchHistory] = useState(getSearchHistory());

  const handleSearchHistoryUpdate = useCallback(() => {
    setSearchHistory(getSearchHistory());
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener(SEARCH_HISTORY_UPDATED_EVENT_NAME, handleSearchHistoryUpdate);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener(SEARCH_HISTORY_UPDATED_EVENT_NAME, handleSearchHistoryUpdate);
      }
    };
  }, [handleSearchHistoryUpdate]);

  return searchHistory;
};
