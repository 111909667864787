import React from 'react';

const DotsMenuIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 3H3V0H0V3ZM4.5 12H7.5V9H4.5V12ZM0 12H3V9H0V12ZM0 7.5H3V4.5H0V7.5ZM4.5 7.5H7.5V4.5H4.5V7.5ZM9 0V3H12V0H9ZM4.5 3H7.5V0H4.5V3ZM9 7.5H12V4.5H9V7.5ZM9 12H12V9H9V12Z"
      fill="#383838"
    />
  </svg>
);

export default DotsMenuIcon;
