import url from 'url';

/**
 * Remove locale part from url path
 *
 * @param path
 * @param locale
 * @returns {string}
 */
export const removeLocaleFromUrl = (path, locale) => {
  const parsed = url.parse(path);

  const regExp = new RegExp(`/${locale}$`, 'igm');
  parsed.pathname = parsed.pathname
    .replace(/\/$/, '')
    .replace(regExp, '')
    .replace(/\/$/, '');

  return url.format(parsed);
};

/**
 * Change locale for url
 *
 * @param path
 * @param fromLocale
 * @param toLocale
 * @returns {string}
 */
export const changeLocaleForUrl = (path, fromLocale, toLocale) => {
  const parsed = url.parse(path);

  const regExp = new RegExp(`/${fromLocale}$`, 'igm');
  const pathnameWithoutLocale = parsed.pathname
    .replace(/\/$/, '')
    .replace(regExp, '')
    .replace(/\/$/, '');

  parsed.pathname = `${pathnameWithoutLocale}/${toLocale}`;

  return url.format(parsed);
};
