import React from 'react';

import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import { useTranslation } from 'src/i18n';
import { getPlaceHolderText } from 'utils/search';
import classes from './SearchButton.module.scss';

const SearchButton = ({ onClickHandler, trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <LinkButton
      title="Search"
      rightIcon={<SpriteIcon icon="search" />}
      variant="tertiary"
      onClick={onClickHandler}
      styles={{ root: classes.searchButton }}
      trackingData={{ subComponent: SUB_COMPONENTS.SEARCH_CTA, ...trackingData }}
    >
      <Typography color="tertiary" weight="medium" size="12" truncate>
        {getPlaceHolderText(t)}
      </Typography>
    </LinkButton>
  );
};

export default SearchButton;
