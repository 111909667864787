import React, { useState } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import dynamic from 'next/dynamic';

import useTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES } from 'components/Globals/Analytics/constants';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';
import LanguageSelector from 'components/Globals/LanguageSelector';

import { useTranslation } from 'src/i18n';

import { PRO_PAGES, TP, BASE_PAGE_ROUTES, ENTITY_TYPE } from 'constants/index';
import routes, { route } from 'constants/routes';
import { AGENT_TYPE_ID } from 'constants/consts';

import { isUpdateEntityPage } from 'utils/search';
import { useDialogs } from 'utils/hooks/useDialogs';
import usePageContext from 'utils/hooks/usePageContext';
import { TRACK_EVENTS, GLOBAL_HEADER_LINKS } from 'utils/tracking';

import DotsMenuIcon from '../Shared/Icons/DotsMenuIcon';
import classes from './HeaderRightBlock.module.scss';
import UpdateProfile from '../../UpdateProfile';

const ProfileDropdown = dynamic(() => import('components/Globals/ProfileDropdown'));
const LoggedInSection = dynamic(() =>
  import('components/Globals/Layout/Header/DesktopHeader/LoggedInSection/LoggedInSection'),
);
const NonLoggedInSection = dynamic(() =>
  import('components/Globals/Layout/Header/DesktopHeader/NonLoggedInSection/NonLoggedInSection'),
);

const LoggedInView = ({ isHideForRegistrationPages, activeProfile, permissions, trackingData = {} }) => {
  const router = useRouter();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate, entityType } = usePageContext();
  const hasCastingToolAccess = permissions?.castingToolPermissions?.hasAccess;
  const isValidProfile =
    activeProfile?.id && (activeProfile?.profileType?.id !== AGENT_TYPE_ID || activeProfile?.agencies?.length > 0);
  const [updateProfileOptions, setUpdateProfileOptions] = useState(false);
  const { type = '' } = router.query;
  const isUpdatePage = isUpdateEntityPage(router, activeProfile);

  const castingToolLinkProps = navigate.getLinkProps({
    baseRoute: BASE_PAGE_ROUTES.CASTING,
    onlyLinkProps: true,
  });

  return (
    <>
      <div className={classnames(classes.block, classes.desktopBlock)}>
        <LoggedInSection isHideForRegistrationPages={isHideForRegistrationPages} trackingData={trackingData} />
      </div>
      <div className={classnames(classes.block, classes.mobileBlock)}>
        <li>
          <LinkButton
            leftIcon={<DotsMenuIcon />}
            styles={{
              root: classnames(classes.proToolsBtn, {
                [classes.isSelected]: type === PRO_PAGES.COMPANIES,
              }),
            }}
            variant="tertiary"
            href={routes.INTRO_AOS}
            isLink
            trackingData={{
              ...trackingData,
              meta: {
                path: routes.INTRO_AOS,
                title: t(hasCastingToolAccess ? `${TP}.AS_PRO` : `${TP}.FN_PRO_CASTING`),
              },
            }}
          >
            <Typography size={14} weight="bold" color="secondary">
              {t(hasCastingToolAccess ? `${TP}.AS_PRO` : `${TP}.FN_PRO_CASTING`)}
            </Typography>
          </LinkButton>
        </li>
        {hasCastingToolAccess && (
          <li>
            <LinkButton
              styles={{
                root: classnames(classes.proToolsBtn, {
                  [classes.isSelected]: router.route === route.CASTING_TOOL && entityType !== ENTITY_TYPE.ARTIST,
                }),
              }}
              variant="tertiary"
              {...castingToolLinkProps}
              isLink
              trackingData={{
                ...trackingData,
                meta: {
                  path: route.CASTING_TOOL,
                  title: t(`${TP}.m_CASTING`),
                },
              }}
            >
              <Typography size={14} weight="bold" color="secondary">
                {t(`${TP}.m_CASTING`)}
              </Typography>
            </LinkButton>
          </li>
        )}
        {!isValidProfile && (
          <li>
            <LinkButton
              leftIcon={<SpriteIcon icon="pencil" />}
              styles={{
                root: classnames(classes.editIcon, {
                  [classes.isSelected]: isUpdatePage,
                }),
                icon: classes.editIcon__icon,
              }}
              variant="tertiary"
              onClick={() => setUpdateProfileOptions(true)}
            />
          </li>
        )}
        <li className={classnames(classes.notificationAndProfile)}>
          <ProfileDropdown />
        </li>
        {updateProfileOptions && (
          <UpdateProfile open={updateProfileOptions} onClose={() => setUpdateProfileOptions(false)} />
        )}
      </div>
    </>
  );
};

const LoggedOutView = ({ trackingData }) => {
  const router = useRouter();
  const { setIsLoginDialog } = useDialogs();
  const { type = '' } = router.query;
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();

  return (
    <>
      <div className={classnames(classes.block, classes.desktopBlock)}>
        <NonLoggedInSection trackingData={trackingData} />
      </div>
      <ul className={classnames(classes.block, classes.mobileBlock)}>
        <li>
          <LinkButton
            variant="tertiary"
            styles={{
              root: classnames(classes.link, {
                [classes.isSelected]: type === PRO_PAGES.COMPANIES,
              }),
              icon: classes.proCastingIcon,
            }}
            href="/pro/companies"
            leftIcon={<DotsMenuIcon />}
            isLink
            trackingData={{
              ...trackingData,
              meta: {
                path: '/pro/companies',
                title: t(`${TP}.FN_PRO_CASTING`),
              },
            }}
          >
            <Typography size={12} weight="bold" color="secondary" className={classes.proCastingText}>
              {t(`${TP}.FN_PRO_CASTING`)}
            </Typography>
          </LinkButton>
        </li>
        <li>
          <LanguageSelector />
        </li>
        <li>
          <LinkButton
            variant="tertiary"
            leftIcon={<SpriteIcon icon="logout_profile" />}
            styles={{
              root: classnames(classes.link, classes.logoutIcon),
            }}
            onClick={() => {
              track.click(
                {
                  name: TRACK_EVENTS.CLICK,
                  data: {
                    id: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_LOGIN_CLICK,
                  },
                },
                GOOGLE_OLD_TRACKING_SERVICES,
              );
              setIsLoginDialog({ isOpen: true });
            }}
          />
        </li>
      </ul>
    </>
  );
};

const HeaderRightBlock = ({
  activeProfile,
  permissions,
  isHideForRegistrationPages,
  isLoggedIn,
  trackingData = {},
}) => {
  if (isLoggedIn) {
    return (
      <LoggedInView
        isHideForRegistrationPages={isHideForRegistrationPages}
        activeProfile={activeProfile}
        permissions={permissions}
        trackingData={trackingData}
      />
    );
  }
  return <LoggedOutView trackingData={trackingData} />;
};

export default HeaderRightBlock;
