import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const RENDERING_TYPES = {
  CLIENT: 'client',
  SERVER: 'server',
};

const useRenderingType = () => {
  const router = useRouter();
  const [renderingType, setRenderingType] = useState(RENDERING_TYPES.SERVER);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Client-side rendering
      setRenderingType(RENDERING_TYPES.CLIENT);
    }
  }, [router.pathname]);

  return renderingType;
};

export default useRenderingType;
