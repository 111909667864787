import React from 'react';

const MenuIcon = ({ color, ...props }) => (
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.25 9.5H13.75V8H0.25V9.5ZM0.25 5.75H13.75V4.25H0.25V5.75ZM0.25 0.5V2H13.75V0.5H0.25Z"
      fill={color || '#383838'}
    />
  </svg>
);

export default MenuIcon;
