import React from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';

import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import { TP } from 'constants/index';
import { useTranslation } from 'src/i18n';

import classes from './UpdateProfileContent.module.scss';

const metaInfo = [
  {
    title: `${TP}.m_PROARTIST`,
    subtitle: `${TP}.FN_PRO_ARTIST_DESC`,
    href: '/pro/artists/details',
    type: 'artists',
  },
  {
    title: `${TP}.FOR_AOS`,
    subtitle: `${TP}.FN_INDUSTRY_PRO_AOS_DESC`,
    href: '/pro/companies/details',
    type: 'companies',
  },
  {
    title: `${TP}.FOR_AGENT_MAIN_TITLE`,
    subtitle: `${TP}.FN_INDUSTRY_PRO_AGENCY_DESC`,
    href: '/pro/agencies/details',
    type: 'agencies',
  },
];

const UpdateProfileContent = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const router = useRouter();
  const { type = '' } = router.query;
  return (
    <div className={classes.wrapper}>
      {metaInfo?.map((item, index) => (
        <LinkButton
          key={index}
          variant="tertiary"
          styles={{ root: classes.optionContainer, icon: classes.arrowIcon }}
          href={item?.href}
          rightIcon={<SpriteIcon icon="chevron_right" size="18" />}
          isLink
        >
          <div className={classes.info}>
            <Typography
              className={classnames(classes.title, {
                [classes.title__isSelected]: type === item.type,
              })}
              weight="medium"
              size="14"
            >
              {t(item.title)}
            </Typography>
            <Typography className={classes.subtitle} variant="p" color="secondary" size="12">
              {t(item.subtitle)}
            </Typography>
          </div>
        </LinkButton>
      ))}
    </div>
  );
};

export default UpdateProfileContent;
