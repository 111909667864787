import { useState, useEffect } from 'react';

const getWidth = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  return null;
};

function useCurrentWidth() {
  const validWindow = typeof window !== 'undefined';
  const [width, setWidth] = useState(getWidth());

  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };

    if (validWindow) {
      window.addEventListener('resize', resizeListener);
    }

    return () => {
      if (validWindow) {
        window.removeEventListener('resize', resizeListener);
      }
    };
  }, [validWindow]);

  return width;
}

export default useCurrentWidth;
