import { escapeBadCharacter } from 'utils/index';
import { changeLocaleForUrl } from './route';

require('dotenv').config();

const baseURL = process.env.FRONTBASE_URL;
const baseLocale = 'en';

const languages = process.env.FRONTBASE_LANGUAGES.split(',');

const defaultTitle = 'Home';
const defaultDescription = 'Welcome to the world of operas, artists, composers';

const defaultOgImage = {
  url: `${baseURL.replace(/\/$/, '')}/images/operabase-logo-large.png`,
  alt: 'Operabase logo',
};

export default function seo({ title, description, canonical, locale, ogTitle, ogImages = [] }) {
  const alternativeLang = languages.reduce((acc, language) => {
    if (language !== 'keys') {
      acc.push({
        hrefLang: language,
        href: changeLocaleForUrl(canonical, locale, language),
      });
    }
    return acc;
  }, []);

  alternativeLang.push({
    hrefLang: 'x-default',
    href: changeLocaleForUrl(canonical, locale, 'en'),
  });

  return {
    title: title || defaultTitle,
    description: description || defaultDescription,
    canonical: canonical || `${baseURL}/${baseLocale}`,
    languageAlternates: alternativeLang,
    openGraph: {
      title: ogTitle || title || defaultTitle,
      description: description || defaultDescription,
      url: canonical || `${baseURL}/${baseLocale}`,
      type: 'website',
      locale,
      site_name: 'Operabase',
      images: ogImages.length > 0 ? ogImages : [defaultOgImage],
    },
    twitter: {
      title: ogTitle || title || defaultTitle,
      description: description || defaultDescription,
      card: 'summary_large_image',
      site: '@operabase_',
      creator: '@operabase_',
    },
    additionalMetaTags: [
      {
        name: 'msapplication-TileColor',
        content: '#f36d2a',
      },
      {
        name: 'msapplication-TileImage',
        content: `${baseURL.replace(/\/$/, '')}/images/favicon/mstile-144x144.png`,
      },
      {
        name: 'theme-color',
        content: '#ffffff',
      },
    ],
  };
}

/**
 * Filter array from empty values and hash strings
 *
 * @param data
 * @returns {*}
 */
export function seoArrayFilter(data) {
  if (Array.isArray(data)) {
    // archival access is a course of sending ### strings instead o real values
    const hashRegexp = /^[#]+$/;
    const escapedData = data?.map(item => {
      if (typeof item === 'string') {
        return escapeBadCharacter(item);
      }
      return item;
    });
    return escapedData?.filter(item => Boolean(item) && (typeof item !== 'string' || !item.match(hashRegexp)));
  }
  return data;
}

/**
 * Create SEO string from array data
 *
 * @param data
 * @returns {string}
 */
export function createSeoStringFromArray(data) {
  if (Array.isArray(data)) {
    return seoArrayFilter(data).join(' | ');
  }
  return '';
}

export const sanitizeStructuredLdJson = obj => {
  if (obj) {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'string') obj[key] = obj[key]?.replace(/"/g, "'") || '';
      else if (typeof obj[key] === 'object') sanitizeStructuredLdJson(obj[key]);
    });
    return obj;
  }
  return '';
};

export const parseSeoMeta = html => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return {
    title: doc?.querySelector('meta[property="og:title"]')?.content || '',
    url: doc?.querySelector('meta[property="og:url"]')?.content || '',
    image: doc?.querySelector('meta[property="og:image"]')?.content || '',
    description: doc?.querySelector('meta[property="og:description"]')?.content || '',
    language: doc?.querySelector('meta[property="og:locale"]')?.content || '',
    canonical: doc?.querySelector('link[rel="canonical"]')?.getAttribute('href') || '',
    robots: doc?.querySelector('meta[name="robots"]')?.content || '',
  };
};
