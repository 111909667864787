import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import useNewTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES, COMPONENTS } from 'components/Globals/Analytics/constants';
import LinkButton from 'components/uiLibrary/LinkButton';
import Image from 'components/uiLibrary/Image';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LanguageSelector from 'components/Globals/LanguageSelector';
import UpdateProfile from 'components/Globals/Layout/UpdateProfile';

import { AGENT_TYPE_ID } from 'constants/consts';

import { useDialogs } from 'utils/hooks/useDialogs';
import { TRACK_EVENTS, GLOBAL_HEADER_LINKS } from 'utils/tracking';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import LogoImage from 'public/images/logo.png';

import classes from './HamburgerMenuHeader.module.scss';

const ProfileDropdown = dynamic(() => import('components/Globals/ProfileDropdown'));

const MenuActions = ({ activeProfile, isLoggedIn, onUpdateProfileClickHandler, isOpen }) => {
  const { setIsLoginDialog } = useDialogs();
  const track = useNewTracking();
  const isValidProfile =
    isLoggedIn &&
    activeProfile?.id &&
    (activeProfile?.profileType?.id !== AGENT_TYPE_ID || activeProfile?.agencies?.length > 0);

  return (
    <div className={classes.block}>
      {!isValidProfile && (
        <li>
          <LinkButton
            leftIcon={<SpriteIcon icon="pencil" />}
            styles={{
              root: classnames(classes.editIcon, {
                [classes.isSelected]: isOpen,
              }),
              icon: classes.editIcon__icon,
            }}
            variant="tertiary"
            onClick={() => onUpdateProfileClickHandler(true)}
            disableUnderline
            disableHover
          />
        </li>
      )}
      {!isLoggedIn ? (
        <>
          <li>
            <LanguageSelector />
          </li>
          <li>
            <LinkButton
              variant="tertiary"
              leftIcon={<SpriteIcon icon="logout_profile" />}
              styles={{
                root: classnames(classes.link, classes.logoutIcon),
              }}
              onClick={() => {
                setIsLoginDialog({ isOpen: true });
                track.click(
                  {
                    name: TRACK_EVENTS.CLICK,
                    data: {
                      id: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_LOGIN_CLICK,
                    },
                  },
                  GOOGLE_OLD_TRACKING_SERVICES,
                );
              }}
            />
          </li>
        </>
      ) : (
        <li className={classnames(classes.notificationAndProfile)}>
          <ProfileDropdown />
        </li>
      )}
    </div>
  );
};

const HamburgerMenuHeader = ({ isLoggedIn, trackingData = {}, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useDeviceTypeLayouts();

  return (
    <>
      <div className={classes.header}>
        <LinkButton
          href="/"
          styles={{
            root: classes.logo,
          }}
          variant="tertiary"
          leftIcon={
            isLoggedIn && isMobile ? (
              <SpriteIcon icon="operabase_icon" />
            ) : (
              <Image
                src={LogoImage}
                alt="Operabase Home"
                height={isMobile ? 12 : 16}
                width={isMobile ? 120 : 160}
                lazy={false}
                className={classes.logoImage}
                disableCloudinary
                disableNextImage
              />
            )
          }
          disableHover
          disableUnderline
          isLink
          trackingData={{ ...trackingData, component: COMPONENTS.OPERABASE_BUTTON }}
        />
        <MenuActions {...props} isOpen={isOpen} isLoggedIn={isLoggedIn} onUpdateProfileClickHandler={setIsOpen} />
      </div>
      {isOpen && <UpdateProfile open={isOpen} onClose={() => setIsOpen(false)} />}
    </>
  );
};

export default HamburgerMenuHeader;
