import { SCROLL_DIRECTION } from 'constants/index';
import { useState, useRef, useEffect, useMemo } from 'react';

const useScrollDirection = ({ threshold = 10, onScrollUp, onScrollDown, enabled = true }) => {
  const [scrollDirection, setScrollDirection] = useState(SCROLL_DIRECTION.UP);

  const blocking = useRef(false);
  const prevScrollY = useRef(0);

  const shouldTrackScroll = useMemo(() => enabled && typeof window !== 'undefined', [enabled]);

  useEffect(() => {
    let onScroll = null;

    if (shouldTrackScroll) {
      prevScrollY.current = window.scrollY;

      const updateScrollDirection = () => {
        const newScrollY = window.scrollY;

        if (Math.abs(newScrollY - prevScrollY.current) >= threshold) {
          const newScrollDirection = newScrollY > prevScrollY.current ? SCROLL_DIRECTION.DOWN : SCROLL_DIRECTION.UP;

          setScrollDirection(newScrollDirection);

          prevScrollY.current = newScrollY > 0 ? newScrollY : 0;
        }

        blocking.current = false;
      };

      onScroll = () => {
        if (!blocking.current) {
          blocking.current = true;
          window.requestAnimationFrame(updateScrollDirection);
        }
      };

      window.addEventListener('scroll', onScroll);
    }

    return () => {
      if (shouldTrackScroll) {
        window.removeEventListener('scroll', onScroll);
      }
    };
  }, [threshold, scrollDirection, onScrollDown, onScrollUp, shouldTrackScroll]);

  useEffect(() => {
    if (shouldTrackScroll) {
      if (scrollDirection === SCROLL_DIRECTION.DOWN && prevScrollY.current > 0) {
        if (typeof onScrollDown === 'function') {
          onScrollDown();
        }
      } else if (typeof onScrollUp === 'function') {
        onScrollUp();
      }
    }
  }, [scrollDirection, onScrollDown, onScrollUp, shouldTrackScroll]);

  return scrollDirection;
};

export default useScrollDirection;
