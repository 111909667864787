import React, { useState } from 'react';
import classnames from 'classnames';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';
import AdvancedSearch from 'components/Search/AdvancedSearch';
import { HeaderNavigation } from 'components/Globals/Layout/Header/Shared/Navigation/HeaderNavigation';

import usePageContext from 'utils/hooks/usePageContext';
import useScrollDirection from 'utils/hooks/useScrollDirection';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { route } from 'constants/routes';

import { BASE_PAGE_ROUTES } from 'constants/index';

import usePermissions from 'utils/permissions';
import HeaderLeftBlock from './HeaderLeftBlock';
import HeaderRightBlock from './HeaderRightBlock';
import HeaderSearch from './Shared/HeaderSearch';
import classes from './Header.module.scss';

const NotificationBanner = dynamic(() => import('components/NotificationBanner'));

const Header = ({
  isLoggedIn,
  onlyHeader,
  isStatic,
  activeProfile,
  hideNotificationBanner,
  activeProfileId,
  hideGlobalNavigation,
  navigationTrackingData,
  searchTrackingData,
}) => {
  const router = useRouter();
  const { isHeaderVisible, isGlobalNavigationVisible, basePath } = usePageContext();
  const permissions = usePermissions();
  const hasCastingToolAccess = permissions?.castingToolPermissions?.hasAccess;
  // const autoHideNavbar = useMemo(() => HIDE_NAVIGATION_ROUTES.includes(router.route), [router]);
  const hasRegistrationRoute = route.REGISTER === router.route;
  const isCastingPage = route.CASTING_TOOL === router.route;
  const showSearchOnMobile = !hasRegistrationRoute && !(isLoggedIn && isCastingPage && hasCastingToolAccess);

  const [showBanner, setShowBanner] = useState(true);
  const { isMobile } = useDeviceTypeLayouts();
  const isScrolled = useScrollTrigger({ disableHysteresis: true, threshold: 350 });
  const hideMainNavigationBar = basePath === BASE_PAGE_ROUTES.ARTISTS && isMobile && isScrolled;

  useScrollDirection({
    onScrollDown: () => {
      if (showBanner) {
        setShowBanner(false);
      }
    },
    onScrollUp: () => {
      if (!showBanner) {
        setShowBanner(true);
      }
    },
    threshold: 0,
  });

  return (
    <>
      <header
        className={classnames(classes.header, {
          [classes.static]: isStatic,
          [classes.hideHeader]: !isHeaderVisible,
          [classes.hideMobileNav]: hideMainNavigationBar,
        })}
      >
        <div className={classes.headerItems}>
          <HeaderLeftBlock isLoggedIn={isLoggedIn} />
          {!hasRegistrationRoute && (
            <div className={classes.desktopSearch}>
              <HeaderSearch trackingData={searchTrackingData} />
            </div>
          )}
          <HeaderRightBlock
            activeProfile={activeProfile}
            permissions={permissions}
            isLoggedIn={isLoggedIn}
            isHideForRegistrationPages={hasRegistrationRoute}
            trackingData={navigationTrackingData}
          />
        </div>
      </header>
      {showSearchOnMobile && !hideGlobalNavigation && (
        <div
          className={classnames(classes.mobileSearch, {
            [classes.static]: isStatic,
            [classes.hideMobileSearch]: !isGlobalNavigationVisible,
            [classes.customMobileSearch]: hideMainNavigationBar,
          })}
        >
          <LinkButton
            styles={{ root: classes.backBtn }}
            variant="tertiary"
            onClick={() => router.back()}
            leftIcon={<SpriteIcon icon="arrow_back" size={24} />}
          />
          <AdvancedSearch trackingData={searchTrackingData} />
        </div>
      )}
      {!onlyHeader && (
        <HeaderNavigation isLoggedIn={isLoggedIn} isStatic={isStatic} trackingData={navigationTrackingData} />
      )}
      {!hideNotificationBanner && <NotificationBanner profileId={activeProfileId} />}
    </>
  );
};

export default Header;
