import React from 'react';

import Drawer from 'components/uiLibrary/Drawer';

import usePermissions from 'utils/permissions';
import { useUserData, useActiveProfileData } from 'utils/hooks/useAuthenticatedUser';
import useAppContext from 'utils/hooks/useAppContext';

import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import HamburgerMenuHeader from './HamburgerMenuHeader';
import HamburgerMenuContent from './HamburgerMenuContent';

import classes from './HamburgerMenu.module.scss';

const HamburgerMenu = ({ isOpen, onClose, trackingData = {} }) => {
  const activeProfile = useActiveProfileData();
  const userData = useUserData();
  const { isLoggedIn } = useAppContext();
  const permissions = usePermissions();
  return (
    <Drawer
      isOpen={isOpen}
      header={
        <HamburgerMenuHeader
          activeProfile={activeProfile}
          permissions={permissions}
          isLoggedIn={isLoggedIn}
          trackingData={trackingData}
        />
      }
      placement="left"
      onClose={onClose}
      className={classes.menuDrawer}
      styles={{ root: classes.drawerRoot, header: classes.menuDrawerHeader }}
      hideHeaderBorder
      trackingData={{ subComponent: SUB_COMPONENTS.CLOSE_CTA, ...trackingData }}
    >
      <HamburgerMenuContent onClose={onClose} isLoggedIn={isLoggedIn} userData={userData} trackingData={trackingData} />
    </Drawer>
  );
};

export default HamburgerMenu;
