import React from 'react';

const DotsMoreIcon = () => (
  <svg width="4" height="12" viewBox="0 0 4 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3ZM2 4.5C1.175 4.5 0.5 5.175 0.5 6C0.5 6.825 1.175 7.5 2 7.5C2.825 7.5 3.5 6.825 3.5 6C3.5 5.175 2.825 4.5 2 4.5ZM2 9C1.175 9 0.5 9.675 0.5 10.5C0.5 11.325 1.175 12 2 12C2.825 12 3.5 11.325 3.5 10.5C3.5 9.675 2.825 9 2 9Z"
      fill="#757171"
    />
  </svg>
);

export default DotsMoreIcon;
