import React from 'react';
import { useMediaQuery } from '@mui/material';

import Drawer from 'components/uiLibrary/Drawer';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Popover from 'components/uiLibrary/Popover';

import { ENTITY_TYPE, TP } from 'constants/index';
import { useTranslation } from 'src/i18n';
import usePageContext from 'utils/hooks/usePageContext';

import UpdateProfileContent from './UpdateProfileContent';

import classes from './UpdateProfile.module.scss';

const UpdateProfile = ({ reference, open, onClose, anchorOrigin, transformOrigin }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { entityType } = usePageContext();
  const isMobile = useMediaQuery('(max-width: 900px)', { noSsr: true });
  if (isMobile) {
    return (
      <Drawer
        leftIcon={<SpriteIcon icon="pencil" size="16" />}
        title={t(entityType === ENTITY_TYPE.ARTIST ? `${TP}.FN_MANAGE_PROFILE` : `${TP}.UPDATE_PROFILE`)}
        isOpen={open}
        onClose={onClose}
      >
        <UpdateProfileContent />
      </Drawer>
    );
  }

  return (
    <Popover
      anchorEl={reference?.current}
      onClose={onClose}
      anchorPosition={{ top: 319 }}
      styles={{ root: classes.updateProfilePopover }}
      anchorOrigin={anchorOrigin || { vertical: 30, horizontal: 395 }}
      transformOrigin={transformOrigin}
    >
      <UpdateProfileContent />
    </Popover>
  );
};

export default UpdateProfile;
