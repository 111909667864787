import React, { Children, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';

import useCurrentWidth from 'utils/hooks/useCurrentWidth';
import useElementSize from 'utils/hooks/useElementSize';

import styles from './CollapsableList.module.scss';

/*
 * CollapsableList
 *
 * Collapses children that can not fit content container.
 * Those that were not able to fit would be passed to CollapsedContainerWrapper
 * as collapsedItems prop.
 * In current build CollapsedContainerWrapper would be displayed always
 * potential feature - wrapper for always show, and flag to hide CCW if not fitting.
 *
 * TODO: Might have an issue with recalculating children width on lng change
 *       Currently recalculating on change of children prop.
 * TODO: Consider debouncing resize event if would be more used.
 */
const CollapsableList = ({ children, className, hideMoreOptions, wrapper = 'div', extraSpace = 20 }) => {
  const [childrenIsDisplayedOpts, setChildrenIsDisplayedOpts] = useState(
    new Array(Children.count(children)).fill(true),
  );
  const [childrenWidth, setChildrenWidth] = useState([]);
  const [childrenParams, setChildrenParams] = useState([]);
  const width = useCurrentWidth();
  const listContainerRef = useRef(null);
  const listContainerSize = useElementSize(listContainerRef);
  const [isReady, setIsReady] = useState(false);

  const containerIndex = useMemo(() => {
    let ind = -1;
    Children.forEach(children, (child, index) => {
      if (child?.type?.displayName === 'CollapsedContainerWrapper') {
        ind = index;
      }
    });
    return ind;
  }, [children]);

  useEffect(() => {
    const totalItems = Children.count(children);
    // get and set all children widths into an array
    if (totalItems > 0) {
      const navBarItems = [...listContainerRef.current?.children];
      const widths = navBarItems?.map(item => {
        // offsetWidth does not include margins.
        const itemStyles = window.getComputedStyle(item);
        // Adding extra space so that on resizing screen the last item is not cut off
        return (
          item.offsetWidth + parseInt(itemStyles.marginLeft, 10) + parseInt(itemStyles.marginRight, 10) + extraSpace
        );
      });
      const params = navBarItems?.map(item => ({ showAlways: item.hasAttribute('data-show-always') }));
      setChildrenWidth(widths);
      setChildrenParams(params);
    }
    setIsReady(true);
  }, []);

  useEffect(() => {
    if (!listContainerSize || !isReady) {
      return;
    }

    // below logic is to decide which children to display
    let combinedWidth = childrenWidth[containerIndex] || 0;
    const params = new Array(childrenIsDisplayedOpts.length).fill(true);
    for (let i = 0; i < childrenIsDisplayedOpts.length; i += 1) {
      if (i !== containerIndex && childrenParams[i]?.showAlways) {
        params[i] = true;
        combinedWidth += childrenWidth[i];
      }
    }
    for (let i = 0; i < childrenIsDisplayedOpts.length; i += 1) {
      if (i !== containerIndex && !childrenParams[i]?.showAlways) {
        params[i] = combinedWidth + childrenWidth[i] < listContainerRef.current?.clientWidth;
        combinedWidth += childrenWidth[i];
      }
    }
    setChildrenIsDisplayedOpts(params);
  }, [childrenWidth, listContainerSize, width, isReady]);

  const collapsedItems = Children.toArray(children).filter((_, index) => !childrenIsDisplayedOpts[index]);

  // when containerIndex is -1 and there are no collapsed items, we don't want to render the container
  const containerChild =
    containerIndex >= 0 && (collapsedItems?.length > 0 || !hideMoreOptions)
      ? React.cloneElement(Children.toArray(children)[containerIndex], { collapsedItems })
      : null;

  if (wrapper === 'div') {
    return (
      <div className={classnames(styles.root, className)} ref={listContainerRef}>
        {Children.map(children, (child, index) => {
          if (index === containerIndex) {
            return containerChild;
          }
          if (childrenIsDisplayedOpts[index]) {
            return child;
          }
          return null;
        })}
      </div>
    );
  }

  return (
    <ul className={classnames(styles.root, className)} ref={listContainerRef}>
      {Children.map(children, (child, index) => {
        if (index === containerIndex) {
          return containerChild;
        }
        if (childrenIsDisplayedOpts[index]) {
          return child;
        }
        return null;
      })}
    </ul>
  );
};

export const CollapsedContainerWrapper = ({ children, collapsedItems }) => children({ collapsedItems });
CollapsedContainerWrapper.displayName = 'CollapsedContainerWrapper';

export default CollapsableList;
