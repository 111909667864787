const whenHubspotReady = () =>
  new Promise((resolve, reject) => {
    if (typeof window === 'undefined') {
      reject(new Error('SSR Call not supported with hubspot'));
    }
    if (window.HubSpotConversations) {
      resolve();
    } else if (typeof window.hsConversationsOnReady !== 'undefined') {
      window.hsConversationsOnReady.push(resolve);
    } else {
      window.hsConversationsOnReady = [resolve];
    }
  });

export const summonLiveChat = () => {
  try {
    window.HubSpotConversations.widget.load();
    window.HubSpotConversations.widget.open();
  } catch (e) {
    console.error('Failed to open live chat, error is:', e);
  }
};

export const loadLiveChatWidget = () => {
  whenHubspotReady().then(() => {
    window.HubSpotConversations.widget.load();
  });
};

export const unloadLiveChatWidget = () => {
  window.HubSpotConversations?.widget?.remove();
};
