import { useCallback, useState } from 'react';
import _isEmpty from 'lodash-es/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import useTracking from 'components/Globals/Analytics';

const useInputSearchTracking = trackingData => {
  const track = useTracking();
  const [sessionId, setSessionId] = useState(null);

  const onFocus = useCallback(() => {
    setSessionId(uuidv4()); // Maybe use a counter variable if it's not necessary to generate a new session id every time
    if (!_isEmpty(trackingData)) {
      track.click({ ...trackingData, meta: { isOpen: true } });
    }
  }, []);

  const onBlur = useCallback(() => {
    setSessionId(null);
    if (!_isEmpty(trackingData)) {
      track.click({ ...trackingData, meta: { isOpen: false } });
    }
  }, []);

  return {
    onFocus,
    onBlur,
    sessionId,
  };
};

export default useInputSearchTracking;
