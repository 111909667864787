import React from 'react';

import LinkButton from 'components/uiLibrary/LinkButton';

const Button = ({
  children,
  onClick,
  variant,
  leftIcon,
  rightIcon,
  disabled,
  type,
  className,
  iconClassName,
  isLoading,
  shape,
  isV4,
  trackingData = {},
  skipTracking = false,
}) => (
  <LinkButton
    onClick={onClick}
    variant={variant}
    leftIcon={leftIcon}
    rightIcon={rightIcon}
    disabled={disabled}
    type={type}
    styles={{ root: className, icon: iconClassName }}
    loading={isLoading}
    shape={shape}
    isV4={isV4}
    trackingData={trackingData}
    skipTracking={skipTracking}
  >
    {children}
  </LinkButton>
);

export default Button;
